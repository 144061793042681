import React, { useEffect } from 'react';

const Termscondition = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			{/* <Header /> */}
			<section className="pt-8 pt-md-11 pb-8 pb-md-14">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-12 col-md">
							<h1 className="display-4 mb-2">Terms of Service</h1>
							<p className="text-gray-700 mb-md-0">Updated 2-Jan-2025</p>
						</div>
					</div>

					<div className="row">
						<div className="col-12">
							<hr className="my-6 my-md-8" />
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-11">
							<p className="text-gray-800">
								These terms and conditions (“<b>Terms</b>”)govern the access to
								and use of the Finvu application and website (“
								<b>Platform</b>”) and the Services (<i>defined below</i>).
							</p>

							<p className="text-gray-800">
								These Terms also include our privacy policy, available at
								www.finvu.in/privacy (“<b>Privacy Policy</b>”), and any
								guidelines, additional terms, policies, or disclaimers made
								available or issued by us from time to time. These Terms may be
								amended by us in accordance with the provisions hereunder, and
								the Terms, as amended from time to time, shall apply to you. You
								may always review the current version of these Terms on the
								Platform.
							</p>

							<p className="text-gray-800">
								These Terms constitute a binding and enforceable legal contract
								between Cookiejar Technologies Private Limited (“<b>Company</b>
								”, “<b>we</b>”, “<b>Finvu</b>”, or “<b>us</b>”)any user of the
								Services and any Financial Information Provider or Financial
								Information User who use our Sandbox environment(“<b>you</b>” or
								“<b>your</b>”). Cookiejar Technologies Private Limited is
								licensed by RBI and holds a Certificate of registration granted
								by the Reserve Bank of India (RBI) to operate as an Account
								Aggregator. The Company is having a valid Certificate of
								Registration dated 06 Mar 2020 issued by the Reserve Bank of
								India under section 45 IA of the Reserve Bank of India Act,
								1934. You represent and warrant that you (a) have full legal
								capacity and authority to agree and bind yourself to these
								Terms, (b) if you are an individual, you are eighteen years of
								age or older, if you are under the age of 18, you should get the
								assistance of a parent or legal guardian to use the Services and
								(c) are an Indian resident. If you represent an entity,
								organisation, or any other legal person, you confirm and
								represent that you have the necessary power and authority to
								bind such entity, organisation, or legal person to these Terms.
							</p>

							<p className="text-gray-800">
								By using our Platform, the Services or the Sandbox, you agree
								that you have read, understood, and are bound by these Terms,
								and that you comply with the requirements listed here. If you do
								not agree to all of these Terms or comply with the requirements
								listed here, please do not access the Platform or use the
								Services and Sandbox.
							</p>

							<h3 className="mb-5">1. DEFINITIONS</h3>

							<p className="text-gray-800">
								(a) “<b>AA Master Directions</b>” shall mean the Master
								Direction - Non-Banking Financial Company - Account Aggregator
								(Reserve Bank) Directions, 2016 as amended, revised, or updated
								from time to time as available at
								<a href="https://www.rbi.org.in/Scripts/BS_ViewMasDirections.aspx?id=10598">
									https://www.rbi.org.in/Scripts/BS_ViewMasDirections.aspx?id=10598
								</a>
								;
							</p>
							<p className="text-gray-800">
								(b) “<b>Customer</b>”for the purpose of these directions means a
								‘person’ who has entered into a contractual arrangement with us
								to avail the Services provided by us or as defined under the AA
								Master Directions;
							</p>
							<p className="text-gray-800">
								(c) “<b>Financial Information</b>”shall have the same meaning
								ascribed to it in the AA Master Directions which includes your
								financial data available with a Financial Information Provider;
							</p>
							<p className="text-gray-800">
								(d) “<b>Financial Information Provider</b>” shall mean a bank,
								banking company, non-banking financial company, asset management
								company, depository, depository participant, insurance company,
								insurance repository, pension fund, or such other entity as may
								be identified as a “<b>financial information provider</b>” in
								the AA Master Directions;
							</p>
							<p className="text-gray-800">
								(e) “<b>Financial Information User</b>” shall mean an entity
								registered with and regulated by any Financial Sector Regulator,
								or such other entity as may be identified as a “
								<b>financial information user</b>” in the AA Master Directions;
							</p>
							<p className="text-gray-800">
								(f) “<b>Financial Sector Regulator</b>” shall have the same
								meaning ascribed to it in the AA Master Directions;
							</p>

							<p className="text-gray-800">
								(g) “<b>Personal Information</b>” or “<b>Personal Data</b>”
								means any data about an individual who is identifiable by or in
								relation to such data;
							</p>
							<p className="text-gray-800">
								(h) “<b>RBI</b>” shall mean the Reserve Bank of India.
							</p>
							<p className="text-gray-800">
								(i) “<b>Sandbox</b>” shall mean the testing environment
								available of the Platform that allows Financial Information
								Provider and Financial Information User assess our offerings.
							</p>
							<p className="text-gray-800">
								Words that are capitalised within the Terms but not defined in
								this Clause 1 shall have the meaning ascribed to them in the
								specific clause.
							</p>

							<h3 className="mb-5">2. SCOPE</h3>

							<p className="text-gray-800">
								(a) “<b>Account Aggregation Services</b>” : Through the
								Platform, you may:
							</p>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(i) retrieve, view, consolidate, organise, and share Financial
									Information that is available with Financial Information
									Providers; and
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(ii) present your Financial Information to Financial
									Information Users.
								</p>
							</div>

							<p className="text-gray-800">
								(b) The Account Aggregation Services offered by us through the
								Platform along with the provision of an access to the Platform
								to the Customer shall be referred to as “<b>Services</b>”.
							</p>
							<p className="text-gray-800">
								(c) For us to render the Services to you, you hereby authorise
								and appoint us as your agent with limited power to access, with
								your consent in accordance with clause 6 of these Terms, your
								Financial Information from Financial Information Providers and
								in turn also share it with the Financial Information Users as
								per your requirement.
							</p>
							<p className="text-gray-800">
								(d) We reserve the right to charge you for the Services in
								accordance with the applicable laws. Should we intend to charge
								for the Services, the fees shall be in line with our pricing
								policy available on our Platform. Additionally, any fees in the
								future shall be levied prospectively and you will be provided a
								prior written notice in this case.
							</p>

							<h3 className="mb-5">3. PROFILE CREATION</h3>

							<p className="text-gray-800">
								(a) To avail the Services, you will be required to create a
								profile on the Platform (“<b>Profile</b>”). In addition to
								setting up a username and password to create the Profile, you
								will be required to furnish certain details, including but not
								limited to name, email address and phone numbers as may be
								required by applicable laws. You warrant that all information
								furnished in connection with your Profile is and shall remain
								accurate and true, and you agree that you shall promptly update
								your details on the Platform in the event of any change to or
								modification of this information.
							</p>
							<p className="text-gray-800">
								(b) You are solely responsible for maintaining the security and
								confidentiality of your AA Handle, username and password and
								agree to immediately notify us of any disclosure or unauthorised
								use of your Profile or any other breach of security with respect
								to your Profile. You will (i) report to us immediately and use
								reasonable efforts to stop any unauthorized use of the Services
								that is known or suspected by you, and (ii) not provide false
								identity information to gain access to or use the Services. “
								<b>AA Handle</b>” means a unique identifier generated upon your
								registration on our Platform, used by the parties to access the
								Platform and render the Services.
							</p>
							<p className="text-gray-800">
								(c) You expressly agree to be liable and accountable for all
								activities that take place through your Profile. We shall in no
								manner be liable for any unauthorised access to your Profile. We
								shall not be liable for any loss of data or functionality caused
								directly or indirectly by your improper use.
							</p>

							<div style={{ marginLeft: '1.5rem' }}>
								<h4 className="mb-5">3.1 KYC</h4>

								<p className="text-gray-800">
									(a) We may be required to undertake certain “know your
									customer” processes in relation to users of the Platform who
									intend to avail certain features of the Services. In this
									regard, from time to time, we shall require you to upload
									information and documents that may be necessary to ascertain
									your eligibility to use certain features of the Services (“
									<b>KYC Documents</b>”).
								</p>
								<p className="text-gray-800">
									(b) You authorise us, and any third-party service provider we
									may engage with, to process your KYC Documents and ascertain
									your eligibility. Any processing undertaken by us shall be in
									accordance with our Privacy Policy. You agree that you may be
									subject to additional terms of service in the event a third
									party processes information under this Clause. Further, in the
									event any additional information, data, or documentation is
									required to determine eligibility (collectively, “
									<b>Additional Information</b>”), you hereby agree to share
									such Additional Information promptly upon request, and
									further, authorise us to process such Additional Information.
								</p>
								<p className="text-gray-800">
									(c) You agree and warrant to provide true, complete, and
									up-to-date KYC Documents and Additional Information. You
									further acknowledge that (i) any false or misleading
									information provided in this regard shall constitute a
									material breach of these Terms, and (ii) your access to
									certain features of the Services may be limited or denied if
									you fail to share KYC Documents and Additional Information.
								</p>

								<h4 className="mb-5">3.2 CONSENT TO USE DATA</h4>

								<p className="text-gray-800">
									(a) We do not store, access, or see any of your financial information. As per the NBFC-AA technical specifications defined by ReBIT (Reserve Bank Information Technology Private Limited), we collect and verify your mobile number to create your AA profile and help you discover your financial accounts. Your mobile number is stored securely in an encrypted format to send SMS notifications about your consent-related actions. Additionally, if any FIP so requires, your PAN and Date of Birth are passed through the Finvu AA system in masked form (as per NBFC-AA technical specifications published bt ReBIT) to help the FIP discover the accurate accounts linked. The PAN and Date of Birth are not stored with Finvu AA at all.
								</p>
								<p className="text-gray-800">
									(b) The provision of our Services enables you to provide your
									consent to the disclosure and transfer of your Financial
									Information. Consent is collected and confirmed through the
									use of a consent artefact, which will require you to confirm
									the following datasets:
								</p>
								<div className="d-flex">
									<span className="mt-1 mr-4"></span>
									<p className="text-gray-800">
										(i) your identity and optional contact information;
									</p>
								</div>
								<div className="d-flex">
									<span className="mt-1 mr-4"></span>
									<p className="text-gray-800">
										(ii) the nature of the Financial Information requested;
									</p>
								</div>
								<div className="d-flex">
									<span className="mt-1 mr-4"></span>
									<p className="text-gray-800">
										(iii) purpose of collecting such information;
									</p>
								</div>
								<div className="d-flex">
									<span className="mt-1 mr-4"></span>
									<p className="text-gray-800">
										(iv) the identity of the recipients of the information;
									</p>
								</div>
								<div className="d-flex">
									<span className="mt-1 mr-4"></span>
									<p className="text-gray-800">
										(v) URL or other address to which notification needs to be
										sent every time the consent artefact is used to access
										information;
									</p>
								</div>
								<div className="d-flex">
									<span className="mt-1 mr-4"></span>
									<p className="text-gray-800">
										(vi) the consent creation date, the date of expiry of the
										consent, and your signature/digital signature;
									</p>
								</div>
								<div className="d-flex">
									<span className="mt-1 mr-4"></span>
									<p className="text-gray-800">
										(vii)any other information that may be required under
										applicable law.
									</p>
								</div>

								<p className="text-gray-800">
									(c) You may, at any point in time, revoke all or any of your
									consent(s) provided to us through the consent artefact to
									obtain any Financial Information that relates to you. Upon
									revocation, the Financial Information Provider and the
									Financial Information User shall be notified of such
									notification.
								</p>
								<p className="text-gray-800">
									(d) You may, at any point in time, access a record of the
									consents provided by you and the Financial Information Users
									with whom the information has been shared on the Platform.
								</p>
								<p className="text-gray-800">
									(e) You agree that we may, in accordance with our Privacy
									Policy, collect and use your personal information and
									technical data and related information.
								</p>
								<p className="text-gray-800">
									(f) We may use information excluding the Financial Information
									and data pertaining to your usage of the Services and
									activities in the Platform for analytics, trends’
									identification, and statistics to further enhance the
									effectiveness and efficiency of the Platform.
								</p>
								<p className="text-gray-800">
									(g) Subject to applicable laws, we may be directed by law
									enforcement agencies or the government and related regulatory
									bodies to disclose data related to you in connection with
									criminal proceedings or any other proceedings. You understand
									and agree that in such instances, we shall have the right to
									share such data with relevant agencies or bodies.
								</p>
							</div>

							<h3 className="mb-5">4. NOTIFICATIONS</h3>

							<p className="text-gray-800">
								(a) You understand that the Company may send you notices,
								communications or alerts from time to time on your registered
								email id or phone number and that you may not be able to opt-out
								of essential communications to you which are necessary for
								provision of the Services.
							</p>
							<p className="text-gray-800">
								(b) In the event that you have disabled the receipt of alerts or
								communication, the Company recommends you revise the same and
								activate the receipt of alerts to its proper destination for
								optimum use of the Services.
							</p>
							<p className="text-gray-800">
								(c) Communications from the Company will strictly be related to
								the Services.
							</p>

							<h3 className="mb-5">5. OUR OBLIGATIONS</h3>

							<p className="text-gray-800">
								(a) We shall provide the Services to you on the basis of your
								explicit consent.
							</p>
							<p className="text-gray-800">
								(b) We shall ensure that the provision of Services to you is
								backed by appropriate agreements and/or authorisations between
								us, you, and the Financial Information Users.
							</p>
							<p className="text-gray-800">
								(c) We do not store any Financial Information that relates to
								you and which we may process in connection with the Services.
							</p>
							<p className="text-gray-800">
								(d) We shall not use the services of a third-party service
								provider for undertaking the business of account aggregation.
							</p>
							<p className="text-gray-800">
								(e) We shall not access your user authentication credentials
								relating to accounts with various Financial Information
								Providers.
							</p>

							<h3 className="mb-5">6. YOUR RESPONSIBILITIES</h3>

							<p className="text-gray-800">
								(a) You represent and warrant that all information that is
								provided through or in relation to the Services is complete,
								true, and correct on the date of agreeing to these Terms and
								shall continue to be complete, true, and correct while you avail
								the Services. Should any information that you provide change
								during the existence of these Terms, you undertake to
								immediately bring such change to our notice and update the same
								through your Profile promptly. You acknowledge that we exercise
								no control over the content of the information transmitted by
								you through the Platform. We do not accept any responsibility or
								liability for any loss or damage that you may suffer or incur if
								any information, documentation, material, or data provided to
								avail the Services is incorrect, incomplete, inaccurate, or
								misleading, or if you fail to disclose any material fact.
							</p>
							<p className="text-gray-800">
								(b) You shall be solely responsible for ensuring compliance with
								applicable laws and shall be solely liable for any liability
								that may arise due to a breach of your obligations in this
								regard.
							</p>
							<p className="text-gray-800">
								(c) You shall extend all cooperation to us in our defence of any
								proceedings that may be initiated against us due to a breach of
								your obligations or covenants under these Terms.
							</p>

							<h3 className="mb-5">7. PROHIBITED ACTIVITIES</h3>
							<p className="text-gray-800">
								(a) You shall not use the Services in any manner except as
								expressly permitted in these Terms. Without limiting the
								generality of the preceding sentence, you shall not:
							</p>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(i) copy, modify, adapt, translate, reverse engineer,
									decompile, disassemble, alter, reproduce or otherwise make any
									changes to the Platform or create any derivative works,
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(ii) use the Platform in any manner to provide time-sharing,
									benchmarking or other computer services to third parties,
									except as expressly provided herein, allow any third party to
									access or benefit from the functionality of the Platform, or
									use the Platform to develop any competing or similar product.
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(iii) use the Services to transmit any data or send or upload
									any material that contains viruses, trojan horses, worms,
									timebombs, keystroke loggers, spyware, adware, or any other
									harmful programmes or similar computer code designed to
									adversely affect the operation of any computer software or
									hardware or destroy or limit the functionality of any computer
									resource;
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(iv) use any robot, spider, other automated device, or manual
									process to monitor or copy the Platform or any portion
									thereof;
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(v) infringes any patent, trademark, copyright or other
									proprietary rights
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(vi) Impersonate or misrepresenting any person or entity,
									including, but not limited to, individuals, officials,
									directors, employees, agents, authorized representatives,
									forum leaders, guides or hosts, or falsely state or otherwise
									misrepresent any information or accounts.
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(vii) deceives or misleads the addressee about the origin of
									the message or knowingly and intentionally communicates any
									misinformation or information which is patently false and
									untrue or misleading in nature or, in respect of any business
									of the Central Government, is identified as fake or false or
									misleading by such fact check unit of the Central Government
									as the Ministry may, by notification published in the Official
									Gazette, specify;
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(viii) threatens the unity, integrity, defence, security or
									sovereignty of India, friendly relations with foreign States,
									or public order, or causes incitement to the commission of any
									cognisable offence, or prevents investigation of any offence,
									or is insulting other nation;
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(ix) impersonates another person;
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(x) contains software virus or any other computer code, file
									or program designed to interrupt,
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(xi) engage in the systematic retrieval of content from the
									Platform to create or compile, directly or indirectly, a
									collection, compilation, database, or directory;
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(xii) use the Services in (A) any unlawful manner, (B) for
									fraudulent or malicious activities, or (C) in any manner
									inconsistent with these Terms; or
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(xiii) violate applicable laws in any manner.
								</p>
							</div>
							<p className="text-gray-800">
								(d) You warrant that you shall not engage in any activity that
								interferes with or disrupts access to the Platform.
							</p>
							<p className="text-gray-800">
								(e) You shall not attempt to gain unauthorised access to any
								portion or feature of the Platform, any other systems or
								networks connected to the Platform, to any of our servers, or
								through the Platform, by hacking, password mining, or any other
								illegitimate means.
							</p>

							<h3 className="mb-5">8. INTELLECTUAL PROPERTY</h3>

							<p className="text-gray-800">
								(a) All rights, title, and interest in and to the Platform,
								Services and the Sandbox, including all intellectual property
								rights arising out of the Platform, Services and the Sandbox,
								are owned by, or otherwise licensed to us. Subject to compliance
								with these Terms, we grant you a non-exclusive,
								non-transferable, non-sub licensable, royalty free, revocable,
								and limited licence to use the Platform, Services and the
								Sandbox in accordance with these Terms and its written
								instructions issued from time to time.
							</p>
							<p className="text-gray-800">
								(b) We may request you to submit suggestions and other feedback,
								including bug reports, relating to the Platform or Services from
								time to time (“<b>Feedback</b>”). You hereby assign to us all
								right, title and interest to such Feedback and an exclusive
								right to create any developments based on such Feedback. We may
								freely use, copy, disclose, publish, display, distribute, and
								exploit the Feedback.
							</p>
							<p className="text-gray-800">
								(c) Except as stated in these Terms, nothing in these Terms
								should be construed as conferring any right in or licence to our
								or any third party’s intellectual property rights.
							</p>

							<h3 className="mb-5">9. TERM AND TERMINATION</h3>

							<p className="text-gray-800">
								(a) These Terms shall remain in effect unless terminated in
								accordance with the terms hereunder.
							</p>
							<p className="text-gray-800">
								(b) We may terminate your access to or use of the Services or
								the Sandbox, or any portion thereof, immediately and at any
								point, at our sole discretion if you violate or breach any of
								the obligations, responsibilities, or covenants under these
								Terms, or when you cease to become a user of our Services or the
								Sandbox.
							</p>
							<p className="text-gray-800">
								(c) Upon termination of these Terms:
							</p>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">(i) the Profile will expire;</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(ii) the Services will “time-out”; and
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(iii) these Terms shall terminate, except for those clauses
									that expressly or are intended to survive termination or
									expiry.
								</p>
							</div>

							<h3 className="mb-5">10. DISCLAIMERS AND WARRANTIES</h3>

							<p className="text-gray-800">
								(a) We do not warrant the accuracy, suitability, or correctness
								of any Financial Information that is made available on or
								through the Services and the Sandbox.
							</p>
							<p className="text-gray-800">
								(b) THE PLATFORM, THE SERVICES AND THE SANDBOX ARE PROVIDED BY
								US ON AN “AS IS” BASIS WITHOUT WARRANTY OF ANY KIND, EXPRESS,
								IMPLIED, STATUTORY OR OTHERWISE, INCLUDING THE IMPLIED
								WARRANTIES OF TITLE, NON- INFRINGEMENT, MERCHANTABILITY, OR
								FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT THAT
								THE SERVICES OR THE SANDBOX WILL OPERATE IN COMBINATION WITH
								YOUR APPLICATIONS, OR WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEMS
								OR DATA PROVIDED BY YOU. YOU ACKNOWLEDGE THAT WE DO NOT CONTROL
								THE TRANSFER OF DATA OVER COMMUNICATIONS FACILITIES, INCLUDING
								THE INTERNET, AND THAT THE SERVICES MAY BE SUBJECT TO
								LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF
								SUCH COMMUNICATIONS FACILITIES. Without limiting the foregoing,
								we make no warranty that:
							</p>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(i) The functions contained in the Platform, the Services or
									the Sandbox will meet your requirements or expectations, or
									that your use of the Services or Sandbox will be
									uninterrupted, timely, secure, or error-free; or
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(ii) any errors or defects in the Platform will be corrected.
								</p>
							</div>
							<p className="text-gray-800">
								(c) No advice or information, whether oral or written, obtained
								by you from us shall create any warranty that is not expressly
								stated in the Terms.
							</p>
							<p className="text-gray-800">
								(d) You hereby accept full responsibility for any consequences
								that may arise from your use of the Services and the Sandbox,
								and expressly agree and acknowledge that we shall have
								absolutely no liability in this regard.
							</p>
							<p className="text-gray-800">
								(e) You hereby understand that some Financial Information sought
								from the Financial Information Provider may be under processing
								or may not have real time information (like for demat folios)
								and hence may not reflect appropriately. This should not be
								construed as deficiency in our Services and accordingly reliance
								on the information supplied by us should be made.
							</p>
							<p className="text-gray-800">
								(f) To the fullest extent permissible by law, we, our
								affiliates, and related parties each disclaim all liability
								towards you for any loss or damage arising out of or due to:
							</p>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(i) your use of, inability to use, or availability or
									unavailability of the Services or the Sandbox including any
									rejection, downtime, malfunction, fraud, or negligence by you.
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(ii) the occurrence or existence of any defect, interruption,
									or delays in the operation or transmission of information to,
									from, or through the Services, communications failure, theft,
									destruction or unauthorised access to our records, programmes,
									services, server, or other infrastructure relating to the
									Services; or
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(iii) the failure of the Services or the Sandbox to remain
									operational for any period of time.
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">(iv) Any Force Majeure Events.</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(v) Scheduled downtime and unscheduled downtime.
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(vi) Change in applicable law which may impact this Terms.
								</p>
							</div>
							<div className="d-flex">
								<span className="mt-1 mr-4"></span>
								<p className="text-gray-800">
									(vii) Revocation of the consent by the Customer.
								</p>
							</div>
							<p className="text-gray-800">
								(g) NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
								NEITHER WE NOR ANY OF OUR AFFILIATES OR RELATED PARTIES SHALL
								HAVE ANY LIABILITY TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT,
								INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR ANY LOSS OF
								REVENUE OR PROFITS ARISING UNDER, DIRECTLY OR INDIRECTLY, OR
								RELATING, IN ANY MANNER WHATSOEVER, TO THESE TERMS OR THE
								SERVICES. YOU AGREES THAT OUR LIABILITY (WHETHER IN CONTRACT,
								TORT (INCLUDING NEGLIGENCE)) HEREUNDER FOR DIRECT DAMAGES,
								REGARDLESS OF THE FORM OF ACTION, SHALL NOT EXCEED INR 10,000.
								THE FOREGOING LIMITATIONS OF LIABILITY SHALL APPLY
								NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
								REMEDY HEREIN.
							</p>
							<p className="text-gray-800">
								(h) If you are using this Platform for the purposes of accessing
								the Sandbox, you understand that we have Sandbox available
								solely for your evaluation of our offerings. In no event shall
								this be considered as a commercial service. In the event, you
								wish to use/avail the subscription to our offerings, you may
								contact us at{' '}
								<a href="support@cookiejar.co.in">support@cookiejar.co.in </a>
							</p>

							<h3 className="mb-5">11. INDEMNITY</h3>
							<p className="text-gray-800">
								You shall indemnify, defend at our option, and hold us, our
								parent company, subsidiaries, affiliates, group companies, joint
								ventures and their officers, employees, directors, agents, and
								representatives, harmless from and against any claim, demand,
								lawsuits, judicial proceeding, losses, liabilities, damages and
								costs (including, without limitation, all damages, liabilities,
								settlements, and attorneys’ fees) due to or arising out of your
								access to the Platform, use of the Services or the Sandbox, any
								act, omission, fraud, negligence or violation of these Terms or
								any infringement of these Terms by you or any third party who
								may use your Profile to access the Services.
							</p>

							<h3 className="mb-5">
								12. JURISDICTION, GOVERNING LAWS, AND DISPUTE RESOLUTION
							</h3>
							<p className="text-gray-800">
								(a) These Terms shall be governed by and construed and enforced
								in accordance with the laws of India. Subject to other
								provisions in this Clause, courts in Pune shall have exclusive
								jurisdiction over all issues arising out of these Terms or the
								use of the Services.
							</p>
							<p className="text-gray-800">
								(b) Any controversies, conflicts, disputes, or differences
								arising out of these Terms shall be resolved by arbitration in
								Pune in accordance with the Arbitration and Conciliation Act,
								1996 for the time being in force, which is deemed to be
								incorporated by reference in this Clause. The language of the
								arbitration shall be English.
							</p>
							<p className="text-gray-800">
								(c) The parties to the arbitration shall keep the arbitration
								confidential and not disclose to any person, other than on a
								need to basis or to legal advisors, unless required to do so by
								law. The decision of the arbitrator shall be final and binding
								on all the parties hereto.
							</p>
							<p className="text-gray-800">
								(d) Each party to the arbitration shall bear its own costs with
								respect to any dispute. You can also raise a dispute with an
								Online Dispute Resolution system
								<a href="https://creatorapp.zohopublic.in/sahamati/odr-process/form-perma/Online_Dispute_Resolution/h5r0JQNQgphGDC5DxQK20B2X5xmbAxgJe8NanupkpfaqwqErCGxepkfCzBRGnCjfanpXPZ8B0yDmzvfUmzZC1CTPhAhtujuGvGEC?Customer_Name1=AxZupn0gAkojsIjczdzZofZ3JTR84HH8UQPD51IRRA44VSfsP3p4SnEUEf3e0GIn&Mobile_Number1=uvm2pU14e5m7EXPBGVCf4zGNnEvGDTXmlc2ooov0cdTayh1RcIdhY5ifXCi40JhF&Key1=y28luqfuMZCm838ejrFqRqM4W7x8t6LBHdNrD%2Fdn35Et4a8TCmz2uB7QkBkkrACPK9R5oHmEfMg%2F2H3UiByquA%3D%3D">
									{' '}
									ODR
								</a>
							</p>

							<h3 className="mb-5">13. GRIEVANCE REDRESSAL</h3>

							<p className="text-gray-800">
								(a) In case you want to raise any grievance please refer to the
								Grievance option on the website
							</p>
							<p className="text-gray-800">
								(b) In any case we shall ensure that your complaint is resolved
								within 30 (thirty) days from the date of the complaint.
							</p>
							<p className="text-gray-800">
								(c) In the event the complaint is not redressed within such
								period, you may appeal to the RBI on below mentioned addresses-
							</p>
							<table
								style={{
									margin: '1rem auto',
									border: '1px solid black',
									borderCollapse: 'collapse',
									padding: '10px',
								}}
							>
								<tbody>
									<tr>
										<td
											colSpan="2"
											style={{
												textAlign: 'center',
												border: '1px solid black',
												padding: '8px',
											}}
										>
											<strong>Mumbai (Regional Office)</strong>
										</td>
									</tr>
									<tr>
										<td style={{ border: '1px solid black', padding: '8px' }}>
											<strong>Jurisdiction</strong>
										</td>
										<td
											style={{
												border: '1px solid black',
												padding: '8px',
											}}
										>
											State of Maharashtra and State of Goa
										</td>
									</tr>
									<tr>
										<td style={{ border: '1px solid black', padding: '8px' }}>
											<strong>Address</strong>
										</td>
										<td
											style={{
												border: '1px solid black',
												padding: '8px',
											}}
										>
											3rd Floor, RBI Building, Opp. Mumbai Central Railway
											Station, Near Maratha Mandir, Byculla, Mumbai – 400 008
										</td>
									</tr>
									<tr>
										<td style={{ border: '1px solid black', padding: '8px' }}>
											<strong>Telephone</strong>
										</td>
										<td
											style={{
												border: '1px solid black',
												padding: '8px',
											}}
										>
											022-2308 4121/2302 8436
										</td>
									</tr>
									<tr>
										<td style={{ border: '1px solid black', padding: '8px' }}>
											<strong>Fax</strong>
										</td>
										<td
											style={{
												border: '1px solid black',
												padding: '8px',
											}}
										>
											022-2302 2011
										</td>
									</tr>
									<tr>
										<td style={{ border: '1px solid black', padding: '8px' }}>
											<strong>E-mail</strong>
										</td>
										<td
											style={{
												border: '1px solid black',
												padding: '8px',
											}}
										>
											<a href="mailto:dnbsmro@rbi.org.in">dnbsmro@rbi.org.in</a>
										</td>
									</tr>
								</tbody>
							</table>

							<h3 className="mb-5">14. MISCELLANEOUS PROVISIONS</h3>
							<p className="text-gray-800">
								(a) <b>Modification:</b> We reserve the right at any time to
								modify these Terms and to add new or additional terms or
								conditions on use of the Services. Such modifications and
								additional terms and conditions will be communicated to you and,
								unless expressly rejected (in which these Terms shall
								terminate), will be effective immediately and will be
								incorporated into these Terms. In the event you refuse to accept
								such changes, these Terms will terminate.
							</p>
							<p className="text-gray-800">
								(b) <b>Severability:</b> If any provision of these Terms is
								determined by any court or other competent authority to be
								unlawful or unenforceable, the other provisions of these Terms
								will continue in effect. If any unlawful or unenforceable
								provision would be lawful or enforceable if part of it were
								deleted, that part will be deemed to be deleted, and the rest of
								the provision will continue in effect (unless that would
								contradict the clear intention of the clause, in which case the
								entirety of the relevant provision will be deemed to be
								deleted).
							</p>
							<p className="text-gray-800">
								(c) <b>Assignment:</b> You shall not license, sell, transfer, or
								assign their rights, obligations, or covenants under these Terms
								in any manner without our prior written consent. We may grant or
								withhold this consent at our sole discretion and subject to any
								conditions we deem appropriate. We may assign our rights to any
								of our affiliates, subsidiaries, or parent companies, or to any
								successor in interest of any business associated with the
								Services without any prior notice to the you.
							</p>
							<p className="text-gray-800">
								(d) <b>Notices:</b> All notices, requests, demands, and
								determinations for us under these Terms (other than routine
								operational communications) shall be sent to
								info@cookiejar.co.in
							</p>
							<p className="text-gray-800">
								(e) <b>Third Party Rights:</b> No third party shall have any
								rights to enforce any terms contained herein.
							</p>
							<p className="text-gray-800">
								(f) <b>Force Majeure:</b> We shall have no liability to you if
								we are prevented from or delayed in performing our obligations
								or from carrying on our business by acts, events, omissions, or
								accidents beyond our reasonable control, including, without
								limitation, strikes, failure of a utility service or
								telecommunications network, act of God, war, riot, civil
								commotion, malicious damage, compliance with any law or
								governmental order, rule, regulation, or direction.("
								<b>Force Majeure Event</b>")
							</p>
							<p className="text-gray-800">
								(g) <b>Translations:</b> We may provide you with translated
								versions of these Terms solely to assist you with understanding
								these Terms in greater detail. In the event of any inconsistency
								between the English version of these Terms and any translated
								version, the terms of the English version shall prevail.
							</p>
						</div>
					</div>
				</div>
			</section>
			{/* <Footer /> */}
		</div>
	);
};

export default Termscondition;
